<template>

</template>

<script>
export default {
  async asyncData({ redirect }) {
    return redirect(301, "https://narasi.tv/event/event-narasi/mata-najwa-panggung-warisan-budaya");
  }
}
</script>
