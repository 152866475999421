<template>
    
</template>

<script>

import config from "/config";

export default {
    async asyncData({app, params, redirect}) {
        try {
          const response = await app.$axios.$get(
          `${config.CORE_SERVICE_API}articles/${params.slug}`
          );
          
          redirect(301, `/playlist/${params.category}/${response.data.channel.slug}/${params.slug}`);
        } catch (error) {
          console.log(error);
        }
    }
}
</script>
