<template>
  <div class="pilihan-narasi-detail-page">
    <AdsHorizontalSlim id="placement_playlist"></AdsHorizontalSlim>
    <ModalShare @registeredModal="modalShare = $event" />
    <Breadcrumbs background="#f1f1f1" />
    <div class="container">
      <div class="video-thumbnail">
        <div class="video-thumbnail__image">
          <div class="image" v-if="thumbnail_pilihan_narasi.loading">
            <Shimmer width="100%" height="100%" />
          </div>
          <ImageResponsive
            v-if="
              thumbnail_pilihan_narasi.items &&
              !thumbnail_pilihan_narasi.loading
            "
            :imageUrl="
              thumbnail_pilihan_narasi.items.thumbnail &&
              thumbnail_pilihan_narasi.items.thumbnail.large
                ? thumbnail_pilihan_narasi.items.thumbnail.large
                : 'https://narasi.tv/storage/images/dummy.png'
            "
            :fromUrl="true"
          />
          <Link
            v-if="playlist_pilihan_narasi.items.length > 0"
            :to="{
              name: 'playlist-category-channel-slug',
              params: {
                category: $route.params.category,
                channel: playlist_pilihan_narasi.items[0].channel.slug,
                slug: playlist_pilihan_narasi.items[0].slug,
              },
            }"
            class="info"
            ><p>PUTAR SEMUA EPISODE</p></Link
          >
        </div>
        <div class="video-thumbnail__detail">
          <h2 class="video-thumbnail__detail__title">
            {{ thumbnail_pilihan_narasi.items.title }}
          </h2>
          <p class="video-thumbnail__detail__total-video">
            {{ thumbnail_pilihan_narasi.items.itemCount }} Video
          </p>
          <div
            class="video-thumbnail__detail__share"
            @click="modalShare.show()"
          >
            <Icons name="bagikan" color="#4A25AA" class="share-icon" />
            <p class="text">Bagikan</p>
          </div>
        </div>
        <div
          v-if="thumbnail_pilihan_narasi.items.description"
          class="video-thumbnail__desc"
          v-html="thumbnail_pilihan_narasi.items.description"
        ></div>
      </div>
      <div class="list-video">
        <AdsRightSticky id="placement_playlist"></AdsRightSticky>
        <template v-if="playlist_pilihan_narasi.loading">
          <ShimmerCardHomeLandscape v-for="(item, index) in 5" :key="index" />
        </template>

        <CardFive
          v-show="!playlist_pilihan_narasi.loading"
          v-for="item in playlist_pilihan_narasi.items"
          :key="item.id"
          :to="{
            name: 'playlist-category-channel-slug',
            params: {
              category: $route.params.category,
              channel: item.channel.slug,
              slug: item.slug,
            },
          }"
          class="swiper-slide"
          :image="
            item.thumbnail && item.thumbnail.large
              ? item.thumbnail.large
              : 'https://narasi.tv/storage/images/dummy.png'
          "
          :imageFromUrl="true"
          :duration="item.timeVideo ? item.timeVideo : '06:00'"
          :title="item.title"
          :channel="item.channel ? item.channel.title : ''"
          :channelSlug="item.channel.slug"
          :programSlug="item.channel.program ? item.channel.program.slug : ''"
          :category="item.category ? item.category.title : ''"
          :categorySlug="item.category ? item.category.slug : ''"
          :isAdvertorial="item.isAdvertorial"
          :isText="item.isText"
        />
        <div class="button-show-more">
          <span
            v-if="
              playlist_pilihan_narasi.items.length <
              playlist_pilihan_narasi.pagination.total
            "
            @click="loadMorePlaylist"
            >{{
              playlist_pilihan_narasi.loadingShowMore
                ? "LOADING"
                : "LIHAT LEBIH BANYAK"
            }}
          </span>
        </div>
      </div>
    </div>
    <AdsHorizontal id="placement_playlist_category_footer"></AdsHorizontal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../../../config";
import CardFive from "/components/cards/CardFive.vue";
import ModalShare from "/components/modal/Share.vue";
import Shimmer from "@/components/Shimmer";
import ImageResponsive from "@/components/ImageResponsive";
import Breadcrumbs from "@/components/Breadcrumbs";
import AdsHorizontalSlim from "@/components/ads/HorizontalSlim";
import AdsRightSticky from "@/components/ads/RightSticky";
import AdsHorizontal from "@/components/ads/Horizontal";
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import ShimmerCardHomeLandscape from "@/components/shimmer/CardHomeLandscape";

export default {
  async asyncData({ app, params, store, route, error }) {
    try {
      const response = await app.$axios.$get(
        `${config.CORE_SERVICE_API}playlists/${params.category}`
      );
      store.commit("seo/SET_SEO", {
        title: "Playlist " + response.data.title,
        desc: response.data.description,
        image:
          response.data.thumbnail && response.data.thumbnail.large
            ? response.data.thumbnail.large
            : "https://narasi.tv/storage/images/dummy.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    AdsHorizontal,
    ShimmerCardHomeLandscape,
    AdsRightSticky,
    Icons,
    Link,
    AdsHorizontalSlim,
    Breadcrumbs,
    ImageResponsive,
    Shimmer,
    ModalShare,
    CardFive,
  },
  data() {
    return {
      modalShare: false,
      page: 1,
    };
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      thumbnail_pilihan_narasi: (state) => {
        return state.videos.thumbnail_pilihan_narasi;
      },
      playlist_pilihan_narasi: (state) => {
        return state.videos.playlist_pilihan_narasi;
      },
    }),
  },
  methods: {
    async initData() {
      await this.$store.dispatch(
        "videos/getThumbnailPilihanNarasi",
        this.$route.params.category
      );
      await this.$store.dispatch("videos/getPlaylistPilihanNarasi", {
        slug: this.$route.params.category,
        page: this.page,
      });
    },
    clickCallback(page) {
      this.page = page;
      window.location.href = this.$router.resolve({
        name: "playlist-category",
        params: {
          category: this.$route.params.category,
        },
        query: {
          page: page,
        },
      }).href;

      this.$store.dispatch("videos/getPlaylistPilihanNarasi", {
        slug: this.$route.params.category,
        page: this.page,
      });
    },
    loadMorePlaylist() {
      this.$store.dispatch("videos/getPlaylistPilihanNarasi", {
        limit: this.playlist_pilihan_narasi.items.length + 10,
        slug: this.$route.params.category,
        page: this.page,
        loadingShowMore: true,
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.pilihan-narasi-detail-page {
  padding: 16px 0 48px;
  @media only screen and (max-width: 1024px) {
    padding: 16px 10px 0;
  }

  #modal-share {
    .modal-header {
      padding: 30px 30px 20px;
      border: none;

      .modal-title {
        font-size: 24px;
        font-weight: 500;
        color: #4a25aa;
      }

      .close {
        cursor: pointer;
        opacity: 1;
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px 30px;

      .sosmed-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        svg {
          width: 32px;
          height: 32px;
          margin-right: 12px;
        }

        .sosmed {
          margin: 0;
          font-size: 18px;
          font-weight: 400;
          color: #828282;
        }
      }
    }
  }

  .container {
    display: flex;
    position: relative;
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      padding: 0;
    }

    .video-thumbnail {
      width: 30%;
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }

      &__image {
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        @media only screen and (max-width: 1024px) {
          border-radius: 0;
        }

        .image {
          width: 100%;
          height: 220px;
        }

        .info {
          position: absolute;
          bottom: 0;
          width: 100%;
          padding: 15px 0;
          background: rgba(66, 66, 66, 0.5);

          p {
            font-size: 14px;
            font-weight: 700;
            color: white;
            text-align: center;
            margin: 0;
          }
        }
      }

      &__detail {
        padding: 24px 0 16px;
        border-bottom: 1px solid lightgray;
        @media only screen and (max-width: 1024px) {
          padding: 16px 20px;
        }

        &__title {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 16px;
          @media only screen and (max-width: 1024px) {
            font-size: 14px;
            margin-bottom: 8px;
          }
        }

        &__total-video {
          font-size: 16px;
          font-weight: 400;
          color: #616161;
          margin-bottom: 16px;
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
            margin-bottom: 8px;
          }
        }

        &__share {
          display: flex;
          align-items: center;
          cursor: pointer;

          svg {
            width: 15px;
            height: 15px;
            margin-right: 10px;
            @media only screen and (max-width: 1024px) {
              width: 10px;
              height: 10px;
              margin-right: 8px;
            }
          }

          .text {
            font-size: 12px;
            font-weight: 500;
            color: #4a25aa;
            margin: 0;
            text-decoration: underline;
          }
        }
      }

      &__desc {
        padding-top: 16px;
        font-size: 14px;
        color: #393c47;
        text-align: justify;
        @media only screen and (max-width: 1024px) {
          padding: 16px 20px;
          border-bottom: 1px solid lightgray;
        }
      }
    }

    .list-video {
      width: 50%;
      padding-left: 30px;
      margin-top: -16px;
      @media only screen and (max-width: 1024px) {
        width: 100%;
        padding: 16px 20px 0;
      }

      a {
        &:first-child {
          .card-home {
            padding-top: 0;
          }
        }

        &:last-child {
          .card-home {
            border: none;
          }
        }

        .card-home {
          border-bottom: 1px solid lightgray;
        }
      }

      .button-show-more {
        text-align: center;
        margin-top: 24px;

        span {
          cursor: pointer;
          height: 50px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #4a25aa;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 700;
          padding: 0 20px;
          @media only screen and (max-width: 1024px) {
            height: 40px;
          }

          &:hover {
            color: #4a25aa !important;
          }
        }
      }
    }
  }
}
</style>
