<template>
  <div class="pilihan-narasi-page">
    <!-- ADS START -->
    <PilihanNarasiPageTopAds />
    <!-- ADS END -->
    <section class="headline">
      <div class="container">
        <h2 class="headline__title">Pilihan Narasi</h2>
        <p class="headline__desc">
          Menampilkan konten-konten berdasarkan topik yang telah dipilih para
          editor Narasi untuk memberi kenyamanan kamu menonton tanpa harus
          mencari.
        </p>
      </div>
    </section>
    <section class="list-pilihan-narasi">
      <div class="container list-card-pilihan-narasi">
        <template v-if="pilihan_narasi.loading">
          <ShimmerCardHomeLandscape v-for="(item, index) in 5" :key="index" />
        </template>
        <div
          v-show="!pilihan_narasi.loading"
          v-for="(item, index) in pilihan_narasi.items"
          :key="`pilihan-${index}`"
        >
          <PilihanNarasiPageHorizontalAds v-if="index === 3" />
          <CardSix
            :to="{
              name: 'playlist-category-channel',
              params: {
                category: item.slug,
                channel: item.channel ? item.channel.slug : '',
              },
              query: {
                ref: 'pilihan-narasi',
              },
            }"
            :toDetail="{
              name: 'playlist-category-channel-slug',
              params: {
                category: item.slug,
                channel:
                  item.channel && item.channel.slug ? item.channel.slug : '',
                slug: item.firstItem,
              },
              query: {
                ref: 'pilihan-narasi',
              },
            }"
            :image="
              item.thumbnail && item.thumbnail.large
                ? item.thumbnail.large
                : 'https://narasi.tv/storage/images/dummy.png'
            "
            :imageFromUrl="true"
            :totalVideo="item.itemCount"
            :title="item.title"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../config";
import CardSix from "/components/cards/CardSix.vue";
import PilihanNarasiPageTopAds from "/components/ads/pilihanNarasi/PilihanNarasiPageTopAds.vue";
import PilihanNarasiPageHorizontalAds from "/components/ads/pilihanNarasi/PilihanNarasiPageHorizontalAds.vue";
import ShimmerCardHomeLandscape from "@/components/shimmer/CardHomeLandscape";

export default {
  async asyncData({ error, store, route }) {
    try {
      store.commit("seo/SET_SEO", {
        title: "Artikel Pilihan Narasi",
        desc: "Menampilkan konten-konten berdasarkan topik yang telah dipilih para editor Narasi untuk memberi kenyamanan kamu menonton tanpa harus mencari.",
        image: "https://narasi.tv/new-icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    ShimmerCardHomeLandscape,
    CardSix,
    PilihanNarasiPageTopAds,
    PilihanNarasiPageHorizontalAds,
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      pilihan_narasi: (state) => {
        return state.videos.pilihan_narasi;
      },
    }),
  },
  methods: {
    async initData() {
      await this.$store.dispatch("videos/getPilihanNarasi");
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.pilihan-narasi-page {
  padding: 16px 0 48px;
  @media only screen and (max-width: 1024px) {
    padding: 32px 0 48px;
  }

  .headline {
    margin-top: 16px;
    margin-bottom: 16px;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 24px;
    }

    .container {
      @media only screen and (max-width: 1024px) {
        padding: 0 20px;
      }
    }

    &__title {
      font-size: 32px;
      font-weight: 700;
      color: #4a25aa;
      margin-bottom: 16px;
      @media only screen and (max-width: 1024px) {
        font-size: 24px;
      }
    }

    &__desc {
      font-size: 16px;
      font-weight: 400;
      color: #757575;
      margin: 0;
      @media only screen and (max-width: 1024px) {
        font-size: 12px;
        color: #0c0b0d;
      }
    }
  }

  .list-pilihan-narasi {
    .list-card-pilihan-narasi {
      .card-pilihan-narasi {
        width: 250px;
        height: fit-content;
        display: flex;
        width: 800px;
        flex-direction: row;
        padding: 16px 0;
        border-bottom: 1px solid lightgray;
        @media only screen and (max-width: 1024px) {
          width: 100%;
        }

        &:last-child {
          border: none;
        }

        &__thumbnail {
          width: 35%;
          position: relative;
          border-radius: 10px;
          overflow: hidden;
          @media only screen and (max-width: 1024px) {
            width: 40%;
          }

          &__image {
            width: 100%;
            height: auto;
          }

          &__total-video {
            width: 50%;
            height: 100%;
            background: rgba(97, 97, 97, 0.5);
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .total-video {
              font-size: 20px;
              font-weight: 500;
              color: #ffffff;
              margin: 0;
              @media only screen and (max-width: 1024px) {
                font-size: 16px;
              }
            }
          }
        }

        &__body {
          width: 65%;
          padding: 0;
          padding-left: 20px;
          align-self: center;
          @media only screen and (max-width: 1024px) {
            width: 60%;
            padding-left: 16px;
          }

          &__title {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            @media only screen and (max-width: 1024px) {
              font-size: 14px;
              color: black;
            }
          }

          &__playlist {
            font-size: 12px;
            font-weight: 500;
            margin: 0;
            color: #4a25aa;
            @media only screen and (max-width: 1024px) {
              color: #616161;
            }

            &:hover {
              color: #4a25aa !important;
            }
          }
        }
      }
    }
  }
}
</style>
