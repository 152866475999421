var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pilihan-narasi-detail-page"},[_c('AdsHorizontalSlim',{attrs:{"id":"placement_playlist"}}),_vm._v(" "),_c('ModalShare',{on:{"registeredModal":function($event){_vm.modalShare = $event}}}),_vm._v(" "),_c('Breadcrumbs',{attrs:{"background":"#f1f1f1"}}),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"video-thumbnail"},[_c('div',{staticClass:"video-thumbnail__image"},[(_vm.thumbnail_pilihan_narasi.loading)?_c('div',{staticClass:"image"},[_c('Shimmer',{attrs:{"width":"100%","height":"100%"}})],1):_vm._e(),_vm._v(" "),(
            _vm.thumbnail_pilihan_narasi.items &&
            !_vm.thumbnail_pilihan_narasi.loading
          )?_c('ImageResponsive',{attrs:{"imageUrl":_vm.thumbnail_pilihan_narasi.items.thumbnail &&
            _vm.thumbnail_pilihan_narasi.items.thumbnail.large
              ? _vm.thumbnail_pilihan_narasi.items.thumbnail.large
              : 'https://narasi.tv/storage/images/dummy.png',"fromUrl":true}}):_vm._e(),_vm._v(" "),(_vm.playlist_pilihan_narasi.items.length > 0)?_c('Link',{staticClass:"info",attrs:{"to":{
            name: 'playlist-category-channel-slug',
            params: {
              category: _vm.$route.params.category,
              channel: _vm.playlist_pilihan_narasi.items[0].channel.slug,
              slug: _vm.playlist_pilihan_narasi.items[0].slug,
            },
          }}},[_c('p',[_vm._v("PUTAR SEMUA EPISODE")])]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"video-thumbnail__detail"},[_c('h2',{staticClass:"video-thumbnail__detail__title"},[_vm._v("\n          "+_vm._s(_vm.thumbnail_pilihan_narasi.items.title)+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"video-thumbnail__detail__total-video"},[_vm._v("\n          "+_vm._s(_vm.thumbnail_pilihan_narasi.items.itemCount)+" Video\n        ")]),_vm._v(" "),_c('div',{staticClass:"video-thumbnail__detail__share",on:{"click":function($event){return _vm.modalShare.show()}}},[_c('Icons',{staticClass:"share-icon",attrs:{"name":"bagikan","color":"#4A25AA"}}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("Bagikan")])],1)]),_vm._v(" "),(_vm.thumbnail_pilihan_narasi.items.description)?_c('div',{staticClass:"video-thumbnail__desc",domProps:{"innerHTML":_vm._s(_vm.thumbnail_pilihan_narasi.items.description)}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"list-video"},[_c('AdsRightSticky',{attrs:{"id":"placement_playlist"}}),_vm._v(" "),(_vm.playlist_pilihan_narasi.loading)?_vm._l((5),function(item,index){return _c('ShimmerCardHomeLandscape',{key:index})}):_vm._e(),_vm._v(" "),_vm._l((_vm.playlist_pilihan_narasi.items),function(item){return _c('CardFive',{directives:[{name:"show",rawName:"v-show",value:(!_vm.playlist_pilihan_narasi.loading),expression:"!playlist_pilihan_narasi.loading"}],key:item.id,staticClass:"swiper-slide",attrs:{"to":{
          name: 'playlist-category-channel-slug',
          params: {
            category: _vm.$route.params.category,
            channel: item.channel.slug,
            slug: item.slug,
          },
        },"image":item.thumbnail && item.thumbnail.large
            ? item.thumbnail.large
            : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"duration":item.timeVideo ? item.timeVideo : '06:00',"title":item.title,"channel":item.channel ? item.channel.title : '',"channelSlug":item.channel.slug,"programSlug":item.channel.program ? item.channel.program.slug : '',"category":item.category ? item.category.title : '',"categorySlug":item.category ? item.category.slug : '',"isAdvertorial":item.isAdvertorial,"isText":item.isText}})}),_vm._v(" "),_c('div',{staticClass:"button-show-more"},[(
            _vm.playlist_pilihan_narasi.items.length <
            _vm.playlist_pilihan_narasi.pagination.total
          )?_c('span',{on:{"click":_vm.loadMorePlaylist}},[_vm._v(_vm._s(_vm.playlist_pilihan_narasi.loadingShowMore
              ? "LOADING"
              : "LIHAT LEBIH BANYAK")+"\n        ")]):_vm._e()])],2)]),_vm._v(" "),_c('AdsHorizontal',{attrs:{"id":"placement_playlist_category_footer"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }