var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pilihan-narasi-page"},[_c('PilihanNarasiPageTopAds'),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('section',{staticClass:"list-pilihan-narasi"},[_c('div',{staticClass:"container list-card-pilihan-narasi"},[(_vm.pilihan_narasi.loading)?_vm._l((5),function(item,index){return _c('ShimmerCardHomeLandscape',{key:index})}):_vm._e(),_vm._v(" "),_vm._l((_vm.pilihan_narasi.items),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.pilihan_narasi.loading),expression:"!pilihan_narasi.loading"}],key:`pilihan-${index}`},[(index === 3)?_c('PilihanNarasiPageHorizontalAds'):_vm._e(),_vm._v(" "),_c('CardSix',{attrs:{"to":{
            name: 'playlist-category-channel',
            params: {
              category: item.slug,
              channel: item.channel ? item.channel.slug : '',
            },
            query: {
              ref: 'pilihan-narasi',
            },
          },"toDetail":{
            name: 'playlist-category-channel-slug',
            params: {
              category: item.slug,
              channel:
                item.channel && item.channel.slug ? item.channel.slug : '',
              slug: item.firstItem,
            },
            query: {
              ref: 'pilihan-narasi',
            },
          },"image":item.thumbnail && item.thumbnail.large
              ? item.thumbnail.large
              : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"totalVideo":item.itemCount,"title":item.title}})],1)})],2)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"headline"},[_c('div',{staticClass:"container"},[_c('h2',{staticClass:"headline__title"},[_vm._v("Pilihan Narasi")]),_vm._v(" "),_c('p',{staticClass:"headline__desc"},[_vm._v("\n        Menampilkan konten-konten berdasarkan topik yang telah dipilih para\n        editor Narasi untuk memberi kenyamanan kamu menonton tanpa harus\n        mencari.\n      ")])])])
}]

export { render, staticRenderFns }